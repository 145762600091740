import { Context } from '@nuxt/types'
import { extend, setInteractionMode } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import { $tt } from '~/plugins/i18n'

setInteractionMode('lazy')

export default function ({ app }: Context) {
  // field required
  extend('required', {
    ...required,
    message: (fieldName): string => {
      return $tt('Invalid {fieldName}', { fieldName })
    }
  })

  // email
  extend('email', {
    ...email,
    message: (): string => {
      return $tt('Email format error')
    }
  })
}
