import { Context } from '@nuxt/types'
import { Wallet } from 'wallet-bridge'
import {
  bsc,
  bscTestnet,
  goerli,
  mainnet as ethereum,
  polygon,
  polygonMumbai
} from '@wagmi/core/chains'
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc'
import { configureChains, createConfig, InjectedConnector } from '@wagmi/core'
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect'
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask'
import debounce from 'lodash.debounce'
import * as Sentry from '@sentry/vue'
import { ME_KEYS } from '~/store/me'
import { isProdData } from '~~/config'

export default function (context: Context, inject: Function) {
  const chainIdToRpc: { [chainId: number]: string | undefined } = {
    [ethereum.id]: 'https://eth.public-rpc.com',
    [goerli.id]: 'https://rpc.ankr.com/eth_goerli',
    [bsc.id]: 'https://bscrpc.com',
    [bscTestnet.id]: 'https://rpc.ankr.com/bsc_testnet_chapel',
    [polygon.id]: 'https://polygon-rpc.com',
    [polygonMumbai.id]: 'https://rpc.ankr.com/polygon_mumbai'
  }

  const { publicClient, chains } = configureChains(
    [ethereum, goerli, bsc, bscTestnet, polygon, polygonMumbai],
    [
      jsonRpcProvider({
        rpc (chain) {
          return { http: chainIdToRpc[chain.id] || '' }
        }
      })
    ]
  )

  const metaMaskConnector = new MetaMaskConnector({
    chains
  })

  const injectedConnector = new InjectedConnector({
    chains
  })

  const walletConnectConnectorOptions = {
    projectId: '13c75e7d20888adc7e57cad417ad9ed8',
    metadata: {
      name: '.bit',
      description: 'Barrier-free DID for Every Community and Everyone',
      url: 'https://d.id',
      icons: ['https://d.id/favicon.png']
    }
  }

  const walletConnectConnectorShow = new WalletConnectConnector({
    chains,
    options: { ...walletConnectConnectorOptions, showQrModal: true }
  })

  const walletConnectConnectorHide = new WalletConnectConnector({
    chains,
    options: { ...walletConnectConnectorOptions, showQrModal: false }
  })

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: [walletConnectConnectorShow, walletConnectConnectorHide, injectedConnector, metaMaskConnector],
    publicClient
  })

  const _wallet = new Wallet({
    isTestNet: !isProdData,
    loggedInSelectAddress: false,
    wagmiConfig,
    gtag: context.app.$gtag
  })

  const reloadPage = debounce((eventName?: string) => {
    if (window.reloading) {
      const error = new Error(`wallet-sdk.ts: reloadPage: reloading is ${window.reloading}，eventName is ${eventName}`)
      window.reloading = false
      console.error(error)
      Sentry.captureException(error)
      return
    }
    window.location.reload()
  }, 1000, { leading: false })

  context.store.dispatch(ME_KEYS.getConnectedAccount)

  _wallet.walletSDK?.context?.addEventListener('walletConnect', () => {
    console.log('wallet-sdk.ts: walletConnect')
    context.store.dispatch(ME_KEYS.getConnectedAccount)
    reloadPage('walletConnect')
  })

  _wallet.walletSDK?.context?.addEventListener('walletDisconnect', () => {
    console.log('wallet-sdk.ts: walletDisconnect')
    context.store.dispatch(ME_KEYS.getConnectedAccount)
    reloadPage('walletDisconnect')
  })

  _wallet.walletSDK?.context?.addEventListener('walletChange', () => {
    console.log('wallet-sdk.ts: walletChange')
    context.store.dispatch(ME_KEYS.getConnectedAccount)
    reloadPage('walletChange')
  })

  _wallet.initWallet({ involution: false }).then(() => {
    context.store.dispatch(ME_KEYS.getConnectedAccount)
  })

  inject('wallets', _wallet)
}
