import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { getWalletState, CoinType, WalletProtocol } from 'wallet-bridge'
import { augmentKeys } from '~/modules/tools'
import { ChainType, CoinTypeToChainIdMap, CoinTypeToChainMap, EvmCoinTypes, IMainChain } from '~/constant/chain'
import { IAccountInfo } from '~/services/Account'

export interface IConnectedAccount {
  address: string
  chain: IMainChain
  protocol: WalletProtocol,
  canAddDevice: boolean,
  backedUp: boolean,
  deviceName: string,
  iCloudPasskeySupport: boolean,
}

type GaUtm = {
  utmSource: string,
  utmMedium: string,
  utmCampaign: string
}

const keys = {
  namespace: 'me',
  // mutations
  setInviter: 'setInviter',
  setChannel: 'setChannel',
  setConnectedAccount: 'setConnectedAccount',
  setRegisteringAccounts: 'setRegisteringAccounts',
  setGaUtm: 'setGaUtm',
  // actions
  fetchRegisteringAccounts: 'fetchRegisteringAccounts',
  getConnectedAccount: 'getConnectedAccount',
  // getters
  computedChainType: 'computedChainType',
  computedChainId: 'computedChainId',
  coinType: 'coinType'
}

export const state = () => ({
  inviter: '',
  channel: '',
  connectedAccount: {
    address: '',
    chain: {},
    canAddDevice: false,
    backedUp: false,
    deviceName: '',
    iCloudPasskeySupport: false
  } as IConnectedAccount,
  registeringAccounts: [] as IAccountInfo[],
  gaUtm: {
    utmSource: '',
    utmMedium: '',
    utmCampaign: ''
  }
})

export type MeState = ReturnType<typeof state>

export const mutations: MutationTree<MeState> = {
  [keys.setInviter]: (state, inviter: string) => {
    state.inviter = inviter
  },
  [keys.setChannel]: (state, channel: string) => {
    state.channel = channel
  },
  [keys.setConnectedAccount]: (state, connectedAccount: IConnectedAccount) => {
    state.connectedAccount = {
      ...state.connectedAccount,
      ...connectedAccount
    }
  },
  [keys.setRegisteringAccounts]: (state, accounts: IAccountInfo[]) => {
    state.registeringAccounts = accounts
  },
  [keys.setGaUtm]: (state, value: GaUtm) => {
    state.gaUtm = value
  }
}

export const actions: ActionTree<MeState, MeState> = {
  async [keys.fetchRegisteringAccounts] ({ commit, state, getters }) {
    if (!state.connectedAccount.address) {
      return
    }
    try {
      const res = await this.$services.account.registeringAccounts({
        chain_type: getters[keys.computedChainType],
        address: state.connectedAccount.address
      })
      commit(keys.setRegisteringAccounts, res && res.registering_accounts)
    }
    catch (err) {
      console.error(err)
      throw err
    }
  },
  [keys.getConnectedAccount] ({ commit }) {
    const { walletSnap } = getWalletState()
    commit(keys.setConnectedAccount, {
      protocol: walletSnap.protocol,
      address: walletSnap.address,
      chain: walletSnap.coinType && CoinTypeToChainMap[walletSnap.coinType],
      canAddDevice: walletSnap.canAddDevice,
      backedUp: walletSnap.deviceList && walletSnap.deviceList.length > 0,
      deviceName: walletSnap.deviceData?.name,
      iCloudPasskeySupport: walletSnap.iCloudPasskeySupport
    })
  }
}

export const getters: GetterTree<MeState, MeState> = {
  [keys.computedChainType] (state): number | undefined {
    const coinType = state.connectedAccount.chain?.coinType
    let value
    if (EvmCoinTypes.includes(coinType)) {
      value = ChainType.eth
    }
    else if (CoinType.trx === coinType) {
      value = ChainType.tron
    }
    else if (CoinType.doge === coinType) {
      value = ChainType.doge
    }
    else if (CoinType.ckb === coinType) {
      value = ChainType.webauthn
    }
    return value
  },
  [keys.computedChainId] (state): number | undefined {
    const coinType = state.connectedAccount.chain?.coinType
    return CoinTypeToChainIdMap[coinType]
  },
  [keys.coinType] (state): string | undefined {
    return state.connectedAccount.chain?.coinType
  }
}

export const ME_KEYS = augmentKeys(keys, keys.namespace)
