import { ActionTree, MutationTree } from 'vuex'
import { augmentKeys } from '~/modules/tools'
import { IReverseInfoRes } from '~/services/DasReverse'
import { ME_KEYS } from '~/store/me'

const keys = {
  namespace: 'reverse',
  // mutations
  setDasReverse: 'setDasReverse',
  // actions
  fetchDasReverse: 'fetchDasReverse'
}

export const state = () => ({
  dasReverse: {
    account: '',
    is_valid: false
  } as IReverseInfoRes
})

export type CommonState = ReturnType<typeof state>

export const mutations: MutationTree<CommonState> = {
  [keys.setDasReverse]: (state, dasReverse: IReverseInfoRes) => {
    state.dasReverse = dasReverse
  }
}

export const actions: ActionTree<CommonState, CommonState> = {
  async [keys.fetchDasReverse] ({ commit, rootState, rootGetters }) {
    // @ts-ignore
    const address = rootState?.me?.connectedAccount?.address
    const coinType = rootGetters[ME_KEYS.coinType]
    if (!address || !coinType) {
      commit(keys.setDasReverse, {
        account: '',
        is_valid: false
      })
      return
    }
    try {
      const res = await this.$services.dasReverse.reverseInfo({
        coinType,
        address
      })
      if (res) {
        commit(keys.setDasReverse, res)
      }
      else {
        commit(keys.setDasReverse, {
          account: '',
          is_valid: false
        })
      }
    }
    catch (err) {
      commit(keys.setDasReverse, {
        account: '',
        is_valid: false
      })
      console.error(err)
      throw err
    }
  }
}

export const REVERSE_KEYS = augmentKeys(keys, keys.namespace)
