import { Context } from '@nuxt/types'
import { ME_KEYS } from '~/store/me'

export default function (context: Context) {
  const { channel, inviter, i, utm_source, utm_medium, utm_campaign } = context.query
  if (channel) {
    context.store.commit(ME_KEYS.setChannel, window.decodeURIComponent(<string>channel))
  }
  if (inviter) {
    context.store.commit(ME_KEYS.setInviter, window.decodeURIComponent(<string>inviter))
  }
  if (i) {
    context.store.commit(ME_KEYS.setInviter, window.decodeURIComponent(<string>i))
  }
  if (utm_source && utm_medium && utm_campaign) {
    context.store.commit(ME_KEYS.setGaUtm, {
      utmSource: window.decodeURIComponent(<string>utm_source),
      utmMedium: window.decodeURIComponent(<string>utm_medium),
      utmCampaign: window.decodeURIComponent(<string>utm_campaign)
    })
  }
}
