import { TxsWithMMJsonSignedOrUnSigned } from 'wallet-bridge'
import { BasicService } from '~/services/BasicService'
import { ACCOUNT_STATUS, ACCOUNT_SUFFIX, DEFAULT_PAGE_SIZE } from '~/constant'
import { splitAccount, validateParams } from '~/modules/tools'
import { ChainType } from '~/constant/chain'

export interface IAccountInfo {
  account: string
  owner: string
  manager: string
  registered_at: number
  expired_at: number
  status: ACCOUNT_STATUS
  account_price: string
  base_amount: string
  owner_chain_type: ChainType
  manager_chain_type: ChainType
  confirm_proposal_hash: string
  cross_coin_type?: string
  enable_sub_account?: boolean
}

interface IOrderDetailParams {
  account: string
  chain_type: number
  address: string
  action: string
}

export interface IOrderDetailRes {
  order_id: string
  account: number
  action: string
  status: string
  timestamp: number
  pay_token_id: string
  pay_amount: string
  inviter_account: string
  register_years: number
  receipt_address: string
  code_url: string
  pay_type: string
  channel_account: string
  coin_type: string
  cross_coin_type: string
  client_secret?: string
}

export interface IMyRewardsResInviteList {
  invitee: string
  reward: string
  invitation_time: string
}

interface IMyRewardsRes {
  count: number
  list: IMyRewardsResInviteList[]
}

interface ISubmitRegisterOrderParams {
  chain_type: number
  address: string
  account: string
  pay_token_id?: string
  pay_address?: string
  pay_type?: string
  register_years: number
  coin_type: string
  inviter_account?: string
  channel_account: string
  cross_coin_type: string
  gift_card?: string
}

interface ISubmitRegisterOrderRes {
  order_id: string
  chain_type?: number
  receipt_address: string
  amount: string
  code_url: string
  token_id: string
  pay_type?: string
  client_secret?: string
}

interface IChangeOrderParams extends ISubmitRegisterOrderParams {
  cross_coin_type: string
}

interface IChangeOrderRes extends ISubmitRegisterOrderRes {}

interface IReturnRegisteredPaymentTrxIdParams {
  chain_type: number
  address: string
  account: string
  order_id: string
  pay_hash: string
}

interface IDasBalancePayParams {
  evm_chain_id: number,
  chain_type: number,
  address: string,
  order_id: string
}

interface IAccountInfoParams {
  chain_type: number,
  address: string,
  category?: number,
  keyword?: string,
  page?: number,
  size?: number
}

interface ICouponCheckRes {
  type: number,
  status: number
}

export default class Account extends BasicService {
  /**
   * Get account information
   * @param accountName
   */
  accountInfo (accountName: string): Promise<IAccountInfo> {
    validateParams({ accountName }, 'accountInfo')
    return this.axios.post('/account/detail', {
      account: accountName
    })
  }

  /**
   * submit register order
   * @param chain_type
   * @param address
   * @param account
   * @param pay_token_id
   * @param pay_address
   * @param pay_type
   * @param register_years
   * @param coin_type
   * @param inviter_account
   * @param channel_account
   * @param cross_coin_type
   * @param gift_card
   */
  submitRegisterOrder ({
    chain_type,
    address,
    account,
    pay_token_id,
    pay_address,
    pay_type,
    register_years,
    coin_type,
    inviter_account = '',
    channel_account = '',
    cross_coin_type = '',
    gift_card = ''
  }: ISubmitRegisterOrderParams): Promise<ISubmitRegisterOrderRes> {
    validateParams({ chain_type, address, account, coin_type }, 'submitRegisterOrder')
    account = account.replace(/\.bit$/, '')
    return this.axios.post('/account/order/register', {
      chain_type,
      address,
      account: account + ACCOUNT_SUFFIX,
      pay_token_id,
      pay_address,
      pay_type,
      register_years,
      coin_type,
      inviter_account,
      channel_account,
      account_char_str: splitAccount(account, true),
      cross_coin_type,
      gift_card
    })
  }

  /**
   * order detail
   * @param chain_type
   * @param address
   * @param account
   * @param action
   */
  orderDetail ({
    chain_type,
    address,
    account,
    action
  }: IOrderDetailParams): Promise<IOrderDetailRes> {
    validateParams({ chain_type, address, account }, 'orderDetail')
    return this.axios.post('/account/order/detail', {
      chain_type,
      address,
      account,
      action
    })
  }

  /**
   * change order
   * @param chain_type
   * @param address
   * @param account
   * @param pay_token_id
   * @param pay_address
   * @param pay_type
   * @param register_years
   * @param coin_type
   * @param cross_coin_type
   * @param inviter_account
   * @param channel_account
   */
  changeOrder ({
    chain_type,
    address,
    account,
    pay_token_id,
    pay_address,
    pay_type,
    register_years,
    coin_type,
    cross_coin_type,
    inviter_account = '',
    channel_account = ''
  }: IChangeOrderParams): Promise<IChangeOrderRes> {
    validateParams({ chain_type, address, account, coin_type }, 'changeOrder')
    return this.axios.post('/account/order/change', {
      chain_type,
      address,
      account,
      pay_token_id,
      pay_address,
      pay_type,
      register_years,
      coin_type,
      inviter_account,
      channel_account,
      cross_coin_type
    })
  }

  /**
   * get my accounts
   * @param chain_type
   * @param address
   * @param keyword
   * @param category
   * @param page
   * @param size
   */
  myAccounts ({
    chain_type,
    address,
    keyword,
    category,
    page = 1,
    size = DEFAULT_PAGE_SIZE
  }: IAccountInfoParams): Promise<{ list: IAccountInfo[], total: number }> {
    validateParams({ chain_type, address }, 'myAccounts')
    return this.axios.post('/account/mine', {
      chain_type,
      address,
      keyword,
      category,
      page,
      size
    })
  }

  /**
   * get registering accounts
   * @param chain_type
   * @param address
   */
  registeringAccounts ({
    chain_type,
    address
  }: { chain_type: number, address: string }): Promise<{ registering_accounts: IAccountInfo[] }> {
    validateParams({ chain_type, address }, 'registeringAccounts')
    return this.axios.post('/account/registering/list', {
      chain_type,
      address
    })
  }

  /**
   * get my rewards
   * @param address
   * @param chainType
   * @param page
   * @param size
   */
  myRewards ({
    address,
    chainType,
    page = 1,
    size = DEFAULT_PAGE_SIZE
  }: { address: string, chainType: number, page?: number, size?: number }): Promise<IMyRewardsRes> {
    validateParams({ chainType, address }, 'myRewards')
    return this.axios.post('/rewards/mine', {
      chain_type: chainType,
      address,
      page,
      size
    })
  }

  /**
   * send transaction
   * @param sign_key
   * @param sign_list
   * @param sign_address
   */
  sendTrx ({
    sign_key,
    sign_list,
    sign_address
  }: TxsWithMMJsonSignedOrUnSigned): Promise<{ hash: string }> {
    validateParams({ sign_key, sign_list }, 'sendTrx')
    return this.axios.post('/transaction/send', {
      sign_key,
      sign_list,
      sign_address
    })
  }

  /**
   * return registered payment transaction ID
   * @param chain_type
   * @param address
   * @param account
   * @param order_id
   * @param pay_hash
   */
  returnRegisteredPaymentTrxId ({
    chain_type,
    address,
    account,
    order_id,
    pay_hash
  }: IReturnRegisteredPaymentTrxIdParams): Promise<any> {
    validateParams({ chain_type, address, account, order_id, pay_hash }, 'returnRegisteredPaymentTrxId')
    return this.axios.post('/account/order/pay/hash', {
      chain_type,
      address,
      account,
      order_id,
      pay_hash
    })
  }

  /**
   * DAS Balance payment
   * @param evm_chain_id
   * @param chain_type
   * @param address
   * @param order_id
   */
  dasBalancePay ({
    evm_chain_id,
    chain_type,
    address,
    order_id
  }: IDasBalancePayParams): Promise<TxsWithMMJsonSignedOrUnSigned> {
    validateParams({ chain_type, address, order_id }, 'dasBalancePay')
    return this.axios.post('/balance/pay', {
      evm_chain_id,
      chain_type,
      address,
      order_id
    })
  }

  /**
   * check coupon
   * @param coupon
   */
  couponCheck (coupon: string): Promise<ICouponCheckRes> {
    validateParams({ coupon }, 'couponCheck')
    return this.axios.post('/account/coupon/check', {
      coupon
    })
  }
}
