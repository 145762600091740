import Axios from 'axios'
import { Context } from '@nuxt/types'
import { TxsWithMMJsonSignedOrUnSigned } from 'wallet-bridge'
import { BasicService } from '~/services/BasicService'
import { TRX_STATUS, REVERSE_TX_STATUS } from '~/constant'
import { reverseApi } from '~~/config'
import { onFulfilled, onRejected } from '~/services/interceptors'
import { validateParams } from '~/modules/tools'

export interface IReverseInfoParams {
  coinType: string,
  address: string
}

export interface ICheckReverseTxStatusParams extends IReverseInfoParams {}

export interface ICheckReverseTxStatusRes {
  status: REVERSE_TX_STATUS,
  account: string,
  action: 'update' | 'remove'
}

export interface ICheckTxStatusParams {
  evm_chain_id: number,
  chain_type: number,
  address: string,
  actions: (string | number)[]
}

export interface ICheckTxStatusRes {
  block_number: number,
  hash: string,
  status: TRX_STATUS
}

export interface ICheckAccountStatusParams extends IReverseInfoParams {
  account: string;
}

export interface ICheckAccountStatusRes {}

export interface IReverseInfoRes {
  account: string,
  is_valid: boolean,
  reverse_status: number
}

export interface ISetDasReverseParams {
  chain_type: number,
  evm_chain_id: number,
  address: string,
  account: string,
}

export interface ISearchAccountsParams extends IReverseInfoParams {
  keyword: string
}

export interface ISearchAccountsRes {
  list: Array<{account: string}>
}

export interface IUpdateReverseParams extends ICheckAccountStatusParams {
  action?: 'update' | 'remove'
}

export interface IUpdateReverseRes {
  sign_msg: string;
  sign_type: number;
  sign_key: string;
}

export interface ISendSignatureParams {
  sign_key: string;
  signature: string;
  sign_address?: string;
}

export interface ISendTxRes {
  hash: string
}

export interface ISendSignatureRes {}

export default class DasReverse extends BasicService {
  constructor (context: Context) {
    super(context)
    this.axios = Axios.create({
      baseURL: reverseApi,
      withCredentials: true
    })
    this.axios.interceptors.response.use(onFulfilled(context), onRejected())
  }

  reverseInfo ({
    coinType,
    address
  }: IReverseInfoParams): Promise<IReverseInfoRes> {
    validateParams({ coinType, address }, 'reverseInfo')
    return this.axios.post('/reverse/info', {
      type: 'blockchain',
      key_info: {
        coin_type: coinType,
        key: address
      }
    })
  }

  /**
   * set das reverse
   * @param evm_chain_id
   * @param chain_type
   * @param address
   * @param account
   */
  setDasReverse ({
    evm_chain_id,
    chain_type,
    address,
    account
  }: ISetDasReverseParams): Promise<TxsWithMMJsonSignedOrUnSigned> {
    validateParams({ chain_type, address, account }, 'setDasReverse')
    return this.axios.post('/reverse/declare', {
      chain_type,
      evm_chain_id,
      address,
      account
    })
  }

  /**
   * delete reverse record
   * @param chain_type
   * @param evm_chain_id
   * @param address
   * @param account
   */
  deleteDasReverse ({
    chain_type,
    evm_chain_id,
    address
  }: {
    chain_type: number,
    address: string,
    evm_chain_id: number
  }): Promise<TxsWithMMJsonSignedOrUnSigned> {
    validateParams({ chain_type, address }, 'deleteDasReverse')
    return this.axios.post('/reverse/retract', {
      chain_type,
      evm_chain_id,
      address
    })
  }

  searchAccounts ({ keyword, coinType, address }: ISearchAccountsParams): Promise<ISearchAccountsRes> {
    validateParams({ coinType, address }, 'searchAccounts')
    return this.axios.post('/account/search', {
      type: 'blockchain',
      account: keyword,
      key_info: {
        coin_type: coinType,
        key: address
      }
    })
  }

  checkAccountStatus ({
    coinType,
    address,
    account
  }: ICheckAccountStatusParams): Promise<ICheckAccountStatusRes> {
    validateParams({ coinType, address, account }, 'checkAccountStatus')
    return this.axios.post('/account/check', {
      type: 'blockchain',
      key_info: {
        coin_type: coinType,
        key: address
      },
      account
    })
  }

  updateReverse ({
    coinType,
    address,
    account,
    action = 'update'
  }: IUpdateReverseParams): Promise<IUpdateReverseRes> {
    validateParams({ coinType, address, account }, 'updateReverse')
    return this.axios.post('/reverse/update', {
      type: 'blockchain',
      key_info: {
        coin_type: coinType,
        key: address
      },
      action,
      account
    })
  }

  sendSignature ({
    sign_key,
    signature,
    sign_address
  }: ISendSignatureParams): Promise<ISendSignatureRes> {
    validateParams({ sign_key, signature }, 'sendSignature')
    return this.axios.post('/reverse/send', {
      sign_key,
      signature,
      sign_address
    })
  }

  sendTx ({ sign_key, sign_list, sign_address }: TxsWithMMJsonSignedOrUnSigned): Promise<ISendTxRes> {
    validateParams({ sign_key, sign_list }, 'sendTx')
    return this.axios.post('/transaction/send', {
      sign_key,
      sign_list,
      sign_address
    })
  }

  // Since the new reverse transaction is sent by backend, we cannot get tx hash directly. The only way that we could check the latest tx status is to call this API.
  checkReverseTxStatus ({
    coinType,
    address
  }: ICheckReverseTxStatusParams): Promise<ICheckReverseTxStatusRes> {
    validateParams({ coinType, address }, 'checkReverseTxStatus')
    return this.axios.post('/reverse/status', {
      type: 'blockchain',
      key_info: {
        coin_type: coinType,
        key: address
      }
    })
  }

  // To check old reverse transaction status.
  // Note: this API will only check if the transaction is pending. If it is pending, there will be a 200 response, otherwise it will throw an error.
  // Event if the tx is successful, it will throw an error, and the error code is errno.apiErrorCodeNotExitsTrx
  checkTxStatus ({
    evm_chain_id,
    chain_type,
    address,
    actions
  }: ICheckTxStatusParams): Promise<ICheckTxStatusRes> {
    validateParams({ chain_type, address }, 'checkTxStatus')
    return this.axios.post('/transaction/status', {
      evm_chain_id,
      chain_type,
      address,
      actions
    })
  }
}
